<script>
import { Icon } from '@components';

let { icon, href, blank, children, ...rest } = $props();

let target = $state();

if (!href) {
  // This is needed to make the link focussable and 'clickable' with keys
  href = "javascript:;"
}

if (blank) {
  target="_blank";
}

</script>

<style>

a {
  display: inline-flex;
  align-items: last baseline;

  Icon {
    margin-right: 0.1rem;
  }
}

</style>

<a {href} {target} {...rest}>
  {#if icon}<Icon {icon}>{@render children()}</Icon>
  {:else}
    {@render children()}
  {/if}
</a>
