<script module>
  import './init.js';
</script>

<script>
  //import 'vite/modulepreload-polyfill'

  import { onMount } from 'svelte';

  import { checkAuthStatus } from '@api/api.js';

  import Router, { preloaded } from './components/Router.svelte';
  import { routes } from './generatedRoutes.svelte.js';

  import Layout from './components/Layout.svelte';

  //Pages

  //import Home         from '@pages/Home.svelte';

  //import Anabin       from '@pages/Anabin.svelte';
  //import About        from '@pages/About.svelte';
  //import Account      from '@pages/Account.svelte';
  //import Contact      from '@pages/Contact.svelte';
  //import Demo         from '@pages/Demo.svelte';
  //import Documents    from '@pages/Documents.svelte';
  //import FAQ          from '@pages/FAQ.svelte';
  //import GetStarted   from '@pages/GetStarted.svelte';
  //import Imprint      from '@pages/Imprint.svelte';
  //import Jobs         from '@pages/Jobs.svelte';
  //import Press        from '@pages/Press.svelte';
  //import Pricing      from '@pages/Pricing.svelte';
  //import Privacy      from '@pages/Privacy.svelte';
  //import SignIn       from '@pages/SignIn.svelte';
  //import Terms        from '@pages/Terms.svelte';
  //import Testimonials from '@pages/Testimonials.svelte';

  //import NotFound     from '@pages/NotFound.svelte';

  //Do we still have to import the favicon to include it in the bundle (and therefore manifest)?
  import '@assets/favicon.svg';

  //const isProduction = process.env.NODE_ENV === 'production';

  onMount(() => {
    // Initialize with the light mode by default
    //document.documentElement.classList.add('has-light-background');
    checkAuthStatus();
  });

  /*
  const routes = {
    '/':             () => import('./pages/Home.svelte'),

    '/about':        () => import('./pages/About.svelte'),
    '/account':      () => import('./pages/Account.svelte'),
    //'/anabin':       () => import('./pages/Anabin.svelte'),
    '/anabin':       preloaded(Anabin),
    '/contact':      () => import('./pages/Contact.svelte'),
    '/demo':         () => import('./pages/Demo.svelte'),
    '/documents':    () => import('./pages/Documents.svelte'),
    '/faq':          () => import('./pages/FAQ.svelte'),
    '/get-started':  () => import('./pages/GetStarted.svelte'),
    '/imprint':      () => import('./pages/Imprint.svelte'),
    '/jobs':         () => import('./pages/Jobs.svelte'),
    '/press':        () => import('./pages/Press.svelte'),
    '/pricing':      () => import('./pages/Pricing.svelte'),
    '/privacy':      () => import('./pages/Privacy.svelte'),
    '/signin':       () => import('./pages/SignIn.svelte'),
    '/terms':        () => import('./pages/Terms.svelte'),
    '/testimonials': () => import('./pages/Testimonials.svelte'),

    '*':             () => import('./pages/NotFound.svelte'),
  };
   */

</script>

<Router routes={routes} Layout={Layout} />

