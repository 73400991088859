import { personStore as person, authLoading } from '@stores';

// TODO: Take key from window.config
const siteKey = '6Le_CikqAAAAAN0mZ33jMr1aAVuTZO4UaZ7ApoJi';

// Helper function to get a cookie by name
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export async function apiRequest(endpoint, options = {}) {
  if (!options.headers) options.headers = {};
  // Set CSRF Token Header for relevant methods
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(options.method?.toUpperCase())) {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      options.headers['X-CSRFToken'] = csrfToken;
    }
  }
  options.credentials = 'include';

  let result;

  try {
    console.log('API Request Initiated:', { endpoint, options })
    const response = await fetch(endpoint, options);
    const data = await response.json();
    result = response.ok
      ? { success: true, data, error: null }
      : { success: false, data: null, error: data ?? response };

  } catch (error) {
    result = { success: false, data: null, error: error };
  }

  // Log the response based on success or failure
  console[result.success ? 'log' : 'error']('API Response:', result);

  return result;
}

// Internal functions unchanged - still returning data directly

export async function searchDegrees(searchString, country = '') {
  const encodedSearchString = '?name=' + encodeURIComponent(searchString);
  const encodeCountryString = country ? '&country=' + encodeURIComponent(country.toUpperCase()) : '';
  return await apiRequest(`${window.config.apiBaseUrl}/degrees/${encodedSearchString}${encodeCountryString}`);
}

export async function searchInstitutions(searchString, country = '') {
  const encodedSearchString = '?name=' + encodeURIComponent(searchString);
  const encodeCountryString = country ? '&country=' + encodeURIComponent(country.toUpperCase()) : '';
  return apiRequest(`${window.config.apiBaseUrl}/institutions/${encodedSearchString}${encodeCountryString}`);
}

async function clearAuth() {
  person.set(null);
}

export async function signOut() {
  const response = await apiRequest(`${window.config.apiBaseUrl}/signout`);
  if (response.success) await clearAuth();
  return response.success;
}

export async function signIn(token) {
  if (!token) {
    console.error('No token provided');
    return false;
  }

  authLoading.set('api_loading');
  const response = await apiRequest(`${window.config.apiBaseUrl}/token-login2/?token=${token}`);

  if (response.success) {
    const { session_key, session_id } = response.data;
    localStorage.setItem('session_key', session_key);
    localStorage.setItem('session_id', session_id);
  } else {
    await clearAuth();
  }

  authLoading.set('api_loaded');
  return response.success;
}

export async function checkAuthStatus() {
  const cachedAuthStatus = localStorage.getItem('authStatus');
  const cachedAuthTime = localStorage.getItem('authStatusTime');

  console.log('checkAuthStatus()');

  /*
  if (cachedAuthStatus && cachedAuthTime) {
    const now = new Date().getTime();
    const lastChecked = parseInt(cachedAuthTime, 10);
    if (now - lastChecked < 5 * 60 * 1000) {
      return cachedAuthStatus === 'true';
    }
  }
  */

  if (!person) {
    console.log('No person');
    return false;
  }

  const response = await apiRequest(`${window.config.apiBaseUrl}/ping/`);
  const isAuthenticated = response.success && response.data.detail === "Authenticated";

  console.log('Auth ping response: ', isAuthenticated, person);

  if (isAuthenticated) {
    localStorage.setItem('authStatus', 'true');
    localStorage.setItem('authStatusTime', new Date().getTime().toString());
    // TODO: How to check for person?
    //fetchUserInfo();
  } else {
    await clearAuth();
    localStorage.setItem('authStatus', 'false');
    localStorage.setItem('authStatusTime', new Date().getTime().toString());
  }

  return isAuthenticated;
}

export async function fetchUserInfo() {
  const response = await apiRequest(`${window.config.apiBaseUrl}/person/`);
  if (response.success) {
    person.set(response.data);
  } else {
    await clearAuth();
  }
  authLoading.set('api_loaded');
  return response.success;
}

export async function fetchPersonProcesses(personId, processId) {
  let url = `${window.config.apiBaseUrl}/person/${personId}/processes`;
  if (processId) {
    url += `/${processId}`;
  }

  const response = await apiRequest(url);

  if (response.success) {
    person.update((current) => {
      // Ensure processes is a map with process IDs as keys
      let processes = current.processes || {};

      if (Array.isArray(processes)) {
        // Convert array to a map if it's not already
        processes = Object.fromEntries(processes.map((p) => [p.id, p]));
      }

      // Update or add the specific process if processId is provided
      if (processId) {
        processes[processId] = response.data[0];
      } else {
        // Replace all processes if no specific processId
        processes = Object.fromEntries(response.data.map((p) => [p.id, p]));
      }

      return { ...current, processes };
    });
  }
  
  return response; // Now returns { success, data, error }
}

export async function anabinAiSearch() {
  return apiRequest(`${window.config.apiBaseUrl}/anabin-ai-search/`, {
    method: 'POST',
  });
}

export async function uploadFile(file, onProgress) {
  const url = `${window.config.apiBaseUrl}/upload/`;

  const formData = new FormData();
  formData.append("file", file);

  const xhr = new XMLHttpRequest();
  const total = file.size;

  return new Promise((resolve) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        onProgress(event.loaded, total);
      }
    });

    xhr.open('POST', url, true);
    xhr.withCredentials = true;

    const csrfToken = getCookie('csrftoken');
    if (csrfToken) xhr.setRequestHeader('X-CSRFToken', csrfToken);

    xhr.onreadystatechange = function() {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve({ success: true, data: JSON.parse(xhr.responseText), error: null });
        } else {
          resolve({ success: false, data: null, error: xhr.statusText || 'File upload failed' });
        }
      }
    };

    xhr.send(formData);
  });
}

export async function submitProcessTask(processId, taskSlug, data = {}, files = [], onProgress) {
  const url = `${window.config.apiBaseUrl}/person/process/${processId}/task/${taskSlug}/`;

  const formData = new FormData();

  // Append each data field (assuming data is an object with key-value pairs)
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }

  // Append each file under the same key 'files' for backend to handle as an array
  files.forEach((file) => {
    formData.append('files', file); // Use the same key name for all files
  });

  const xhr = new XMLHttpRequest();

  return new Promise((resolve) => {
    // Monitor upload progress
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable && onProgress) {
        onProgress(event.loaded, event.total);
      }
    });

    xhr.open('POST', url, true);
    xhr.withCredentials = true;

    // Set CSRF token if available
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      xhr.setRequestHeader('X-CSRFToken', csrfToken);
    }

    xhr.onreadystatechange = function() {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve({ success: true, data: JSON.parse(xhr.responseText), error: null });
        } else {
          resolve({ success: false, data: null, error: xhr.statusText || 'Request failed' });
        }
      }
    };

    xhr.send(formData);
  });
}

export async function submitContactForm({ name, email, message }) {
  const recaptchaToken = await grecaptcha.execute(siteKey, { action: 'contact_form/submit' });
  const payload = { name, email, message, recaptcha: recaptchaToken };

  const response = await apiRequest(`${window.config.apiBaseUrl}/contact/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  return response.success ? response.data : { error: response.error };
}

export async function submitSigninForm({ email }) {
  const recaptchaToken = await grecaptcha.execute(siteKey, { action: 'signin_form/submit' });
  const payload = { email, recaptcha: recaptchaToken };

  const response = await apiRequest(`${window.config.apiBaseUrl}/signin-request/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  return response.success ? response.data : { error: response.error };
}

export async function submitTermsForm(agree) {
  const recaptchaToken = await grecaptcha.execute(siteKey, { action: 'terms_form/submit' });
  const payload = { accept: agree, recaptcha: recaptchaToken };

  const response = await apiRequest(`${window.config.apiBaseUrl}/accept-terms/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  return response.success ? response.data : { error: response.error };
}
