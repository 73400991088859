export const routes = {
  "*": () => import('.//pages/404.svelte'),
  "/about": () => import('.//pages/About.svelte'),
  "/account": () => import('.//pages/Account.svelte'),
  "/anabin": () => import('.//pages/Anabin.svelte'),
  "/contact": () => import('.//pages/Contact.svelte'),
  "/demo": () => import('.//pages/Demo.svelte'),
  "/documents": () => import('.//pages/Documents.svelte'),
  "/faq": () => import('.//pages/FAQ.svelte'),
  "/get-started": () => import('.//pages/GetStarted.svelte'),
  "/": () => import('.//pages/Home.svelte'),
  "/imprint": () => import('.//pages/Imprint.svelte'),
  "/jobs": () => import('.//pages/Jobs.svelte'),
  "/not-found": () => import('.//pages/NotFound.svelte'),
  "/press": () => import('.//pages/Press.svelte'),
  "/pricing": () => import('.//pages/Pricing.svelte'),
  "/privacy": () => import('.//pages/Privacy.svelte'),
  "/sign-document": () => import('.//pages/SignDocument.svelte'),
  "/signin": () => import('.//pages/Signin.svelte'),
  "/submission-form-test": () => import('.//pages/SubmissionFormTest.svelte'),
  "/terms": () => import('.//pages/Terms.svelte'),
  "/testimonials": () => import('.//pages/Testimonials.svelte'),
  "/account/details": () => import('.//pages/account/Details.svelte'),
  "/account/process": () => import('.//pages/account/Process.svelte'),
  "/account/processes": () => import('.//pages/account/Processes.svelte'),
  "/account/tasks": () => import('.//pages/account/Tasks.svelte'),
  "/account/visa": () => import('.//pages/account/Visa.svelte'),
  "/anabin/aisearch": () => import('.//pages/anabin/AISearch.svelte'),
  "/anabin/degree-search": () => import('.//pages/anabin/DegreeSearch.svelte'),
  "/anabin/institution-search": () => import('.//pages/anabin/InstitutionSearch.svelte')
};
